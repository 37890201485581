import { saveArticle } from "../api/articles";

export const FETCH_ARTICLES_PENDING = 'FETCH_ARTICLES_PENDING';
export const FETCH_ARTICLES_SUCCESS = 'FETCH_ARTICLES_SUCCESS';
export const FETCH_ARTICLES_ERROR   = 'FETCH_ARTICLES_ERROR';

export const fetchArticlesPending = () => ({
  type: FETCH_ARTICLES_PENDING
})

export const fetchArticlesSuccess = (articles) => ({
  type: FETCH_ARTICLES_SUCCESS,
  payload: articles
})

export const fetchArticlesError = (error) => ({
  type: FETCH_ARTICLES_ERROR,
  error: error
})


export const FETCH_ARTICLES_PREVIEW_PENDING = 'FETCH_ARTICLES_PREVIEW_PENDING';
export const FETCH_ARTICLES_PREVIEW_SUCCESS = 'FETCH_ARTICLES_PREVIEW_SUCCESS';
export const FETCH_ARTICLES_PREVIEW_ERROR   = 'FETCH_ARTICLES_PREVIEW_ERROR';

export const fetchArticlesPreviewPending = () => ({
  type: FETCH_ARTICLES_PREVIEW_PENDING
})

export const fetchArticlesPreviewSuccess = (articles) => ({
  type: FETCH_ARTICLES_PREVIEW_SUCCESS,
  payload: articles
})

export const fetchArticlesPreviewError = (error) => ({
  type: FETCH_ARTICLES_PREVIEW_ERROR,
  error: error
})


export const SET_ACTIVE_ARTICLE = 'SET_ACTIVE_ARTICLE';

export const setActiveArticle = id => dispatch => {
  dispatch({
    type: SET_ACTIVE_ARTICLE,
    payload: id
  })
}

export const SAVE_ARTICLE_PENDING = 'SAVE_ARTICLE_PENDING';
export const SAVE_ARTICLE_SUCCESS = 'SAVE_ARTICLE_SUCCESS';
export const SAVE_ARTICLE_ERROR  = 'SAVE_ARTICLE_ERROR';

export const saveArticlePending = () => ({
  type: SAVE_ARTICLE_PENDING
})
export const saveArticleSuccess = (articles) => ({
  type: SAVE_ARTICLE_SUCCESS,
  payload: articles
})
export const saveArticleError = () => ({
  type: SAVE_ARTICLE_ERROR
})

export const DELETE_ARTICLE_PENDING = 'DELETE_ARTICLE_PENDING';
export const DELETE_ARTICLE_SUCCESS = 'DELETE_ARTICLE_SUCCESS';
export const DELETE_ARTICLE_ERROR  = 'DELETE_ARTICLE_ERROR';

export const deleteArticlePending = () => ({
  type: DELETE_ARTICLE_PENDING
})
export const deleteArticleSuccess = (data) => ({
  type: DELETE_ARTICLE_SUCCESS,
  payload: data
})
export const deleteArticleError = () => ({
  type: DELETE_ARTICLE_ERROR
})

export const REORDER_ARTICLES_PENDING = 'REORDER_ARTICLES_PENDING';
export const REORDER_ARTICLES_SUCCESS = 'REORDER_ARTICLES_SUCCESS';
export const REORDER_ARTICLES_ERROR   = 'REORDER_ARTICLES_ERROR';

export const reorderArticlesPending = () => ({
  type: REORDER_ARTICLES_PENDING
})
export const reorderArticlesSuccess = (articles) => ({
  type: REORDER_ARTICLES_SUCCESS,
  payload: articles
})
export const reorderArticlesError = () => ({
  type: REORDER_ARTICLES_ERROR
})

export const UPLOAD_IMAGE_PENDING = 'UPLOAD_IMAGE_PENDING';
export const UPLOAD_IMAGE_SUCCESS = 'UPLOAD_IMAGE_SUCCESS';
export const UPLOAD_IMAGE_ERROR   = 'UPLOAD_IMAGE_ERROR';

export const uploadImagePending = () => ({
  type: UPLOAD_IMAGE_PENDING
})
export const uploadImageSuccess = (image) => ({
  type: UPLOAD_IMAGE_SUCCESS,
  payload: image
})
export const uploadImageError = () => ({
  type: UPLOAD_IMAGE_ERROR
})