import {
    FETCH_PRODUCTS_PENDING,
    FETCH_PRODUCTS_SUCCESS,
    FETCH_PRODUCTS_ERROR,

    FETCH_HOME_PRODUCTS_PENDING,
    FETCH_HOME_PRODUCTS_SUCCESS,
    FETCH_HOME_PRODUCTS_ERROR,

    FETCH_PRODUCT_PENDING,
    FETCH_PRODUCT_SUCCESS,
    FETCH_PRODUCT_ERROR,

    PATCH_TEXT_PRODUCT_SUCCESS,
    POST_SIZE_PRODUCT_SUCCESS,
    DELETE_SIZE_PRODUCT_SUCCESS,

    PATCH_SIZE_PRODUCT_SUCCESS
} from '../actions/products';

const initialState = {
    products: [],
    homeProducts: [],
    product: {},
    error: false,
    pending: true
};

export const products = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_PRODUCTS_PENDING:
            return {
                ...state, 
                pending: true,
                error: false
            }
        case FETCH_PRODUCTS_SUCCESS:
            return {
                ...state, 
                pending: false,
                products: action.payload
            }
        case FETCH_PRODUCTS_ERROR:
            return {
                ...state, 
                pending: false,
                error: action.error
            }
        case FETCH_HOME_PRODUCTS_PENDING:
            return {
                ...state,
                pending: true,
                error: false
            }
        case FETCH_HOME_PRODUCTS_SUCCESS:
            return {
                ...state,
                homeProducts: action.payload,
                pending: false
            }
        case FETCH_HOME_PRODUCTS_ERROR: 
            return {
                ...state,
                error: action.error,
                pending: false
            }
        case FETCH_PRODUCT_PENDING:
            return {
                ...state, 
                pending: true,
                error: false
            }
        case FETCH_PRODUCT_SUCCESS:
            return {
                ...state, 
                product: action.payload,
                pending: false
            }
        case FETCH_PRODUCT_ERROR:
            return {
                ...state, 
                error: true,
                pending: false
            }
        case PATCH_TEXT_PRODUCT_SUCCESS: 
            return {
                ...state,
                product: action.payload,
                error: false
            }
        case POST_SIZE_PRODUCT_SUCCESS: 
            return {
                ...state,
                product: action.payload,
                error: false
            }
        case DELETE_SIZE_PRODUCT_SUCCESS: 
            return {
                ...state,
                product: action.payload,
                error: false
            }
        case PATCH_SIZE_PRODUCT_SUCCESS:
            return {
                ...state,
                product: action.payload,
                error: false
            }   
        default:
            return state
    }
}

export const getProducts = state => state.products.products;
export const getProductsPending = state => state.products.pending;
export const getProductsError = state => state.products.error;

export const getHomeProducts = state => state.products.homeProducts;
export const getHomeProductsPending = state => state.products.pending;
export const getHomeProductsError = state => state.products.error;

export const getProduct = state => state.products.product;
export const getProductPending = state => state.products.pending;
export const getProductError = state => state.products.error;
