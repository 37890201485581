import API from '../api/auth';
import axios from '../api/config';

export const AUTH_PENDING = 'AUTH_PENDING';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_ERROR   = 'AUTH_ERROR';
export const SET_LOGOUT   = 'SET_LOGOUT';



export const login = user => async dispatch => {
    
    dispatch({
        type: AUTH_PENDING
    })

    try {
        const res = await API.auth(user)

        if(res.status !== 200) {
            dispatch({
                type: AUTH_ERROR
            })
            return;
        }

        if(res.status === 200) {
            const {token, user} = res.data;
            dispatch({
                type: AUTH_SUCCESS,
                payload: {
                    token, 
                    user
                }
            })
        }
    } catch(err) {
        dispatch({
            type: AUTH_ERROR
        })
    }
}

export const logout = () => dispatch => {
    axios.defaults.headers.common['Authorization'] = null;
    dispatch({
        type: SET_LOGOUT
    })
}


export const FETCH_AUTH_USER_PENDING = 'FETCH_AUTH_USER_PENDING';
export const FETCH_AUTH_USER_SUCCESS = 'FETCH_AUTH_USER_SUCCESS';
export const FETCH_AUTH_USER_ERROR   = 'FETCH_AUTH_USER_ERROR';

export const fetchAuthUserPending = () => ({
    type: FETCH_AUTH_USER_PENDING
})

export const fetchAuthUserSuccess = (user) => ({
    type: FETCH_AUTH_USER_SUCCESS,
    payload: user
})

export const fetchAuthUserError = () => ({
    type: FETCH_AUTH_USER_ERROR
})