import { fetch, patch, post } from './middleware';
import {
  fetchProductsPending,
  fetchProductsSuccess,
  fetchProductsError,

  fetchHomeProductsPending,
  fetchHomeProductsSuccess,
  fetchHomeProductsError,

  fetchProductPending,
  fetchProductSuccess,
  fetchProductError,

  patchTextProductPending,
  patchTextProductSuccess,
  patchTextProductError,

  postSizeProductPending,
  postSizeProductSuccess,
  postSizeProductError,

  patchSizeProductPending,
  patchSizeProductSuccess,
  patchSizeProductError,

  deleteSizeProductPending,
  deleteSizeProductSuccess,
  deleteSizeProductError,
} from '../actions/products';

export const fetchProducts = () => 
  fetch(
    `/products/list`,
    {
      pending: fetchProductsPending, 
      success: fetchProductsSuccess, 
      error: fetchProductsError
    }
  );

export const fetchHomeProducts = () =>
  fetch(
    `/products/home`,
    {
      pending: fetchHomeProductsPending,
      success: fetchHomeProductsSuccess,
      error: fetchHomeProductsError
    }
  );

export const fetchProduct = url =>
  fetch(
    `/products/${url}`,
    {
      pending: fetchProductPending,
      success: fetchProductSuccess,
      error: fetchProductError
    }
  );

export const patchTextProduct = (url, data) =>
  patch(
    `/products/${url}/text`,
    {
      pending: patchTextProductPending,
      success: patchTextProductSuccess,
      error: patchTextProductError
    },
    {product: data}
  );

export const postSizeProduct = (url, data) =>
  post(
    `/products/${url}/size`,
    {
      pending: postSizeProductPending,
      success: postSizeProductSuccess,
      error: postSizeProductError,
    },
    data
  );

export const patchSizeProduct = (url, data) =>
  patch(
    `/products/${url}/size`,
    {
      pending: patchSizeProductPending,
      success: patchSizeProductSuccess,
      error: patchSizeProductError,
    },
    data
  );

export const deleteSizeProduct = (url, data) => 
  post(
    `/products/${url}/size/delete`,
    {
      pending: deleteSizeProductPending,
      success: deleteSizeProductSuccess,
      error: deleteSizeProductError
    },
    data
  )



