import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import Header from '../Header/Header'
import Main from '../Main/Main'
import Footer from '../Footer/Footer'
import ModalTextEditer from "../Shared/Editer/ModalTextEditer";
import { Button } from 'semantic-ui-react'
import { getEditingMode } from '../../reducers/admin'
import { setEditingMode } from '../../actions/admin'
import { getAuthState, getAuthUser } from '../../reducers/auth'
import { fetchAuthUser } from '../../api/auth';
import AdminSidebar from '../../components/Pages/Admin/AdminSidebar';
import CookiesBanner from '../CookiesBanner/CookiesBanner';
import { areCookiesAccepted, acceptCookies } from '../../localStore';

import '../App.css'

function Layout({ location: { pathname }, editingMode, auth, setEditingMode, fetchAuthUser, user}) {

  const [cookies, setCookies] = useState(!!areCookiesAccepted());

  useEffect(() => {
    if(pathname.includes('/admin')) {
      fetchAuthUser()
    }
  }, [])

  const pageHasFooter = (path) => {
    const PAGES = ['/obchodni-podminky', '/produkty', '/gdpr', '/cookies', '/domu', ''];
    return !!PAGES.includes(path) || path === '/' || path === '';
  } 

  return (
    <div className={`Layout ${auth ? 'Layout-admin' : ''}`} style={{ height: '100%' }}>
      {
        !pathname.includes('/admin') ? ( 
          <>
            {/*auth && (
              <div className='admin-toolbar'>
                <Button onClick={() => setEditingMode(!editingMode)}>Editační mód</Button>
              </div>
            )*/}

            <Header /> 
            <Main />
            {pageHasFooter(pathname) && (<Footer /> )}
          </>  
        ) : (
          <>
            {(auth && user) ? (
              <AdminSidebar>
                <Main adminClass='Main-admin' style={{width: 'calc(100% - 150px)'}} />
              </AdminSidebar>
            ) : <Main adminClass='Main-admin' style={{height: '100%'}} />}
          </>
        )
      }
      <ModalTextEditer />
      
      {!cookies && (
        <CookiesBanner 
          acceptCookies={() => {
            acceptCookies();
            setCookies(true);
          }}
        />
      )}
    </div>
  )
}

const mapStateToProps = state => ({
  productsHome: state.products.productsHome,
  editingMode: getEditingMode(state),
  auth: getAuthState(state),
  user: getAuthUser(state)
})

export default withRouter(connect(mapStateToProps, { setEditingMode, fetchAuthUser })(Layout))
