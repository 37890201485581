export const ADD_TO_CART = 'ADD_TO_CART';

export const addToCart = product => dispatch => 
dispatch({
  type: ADD_TO_CART,  
  payload: product
})

export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';

export const removeFromCart = product => dispatch => {
  dispatch({
    type: REMOVE_FROM_CART,
    payload: product
  })
}

export const CHANGE_CURRENCY = 'CHANGE_CURRENCY';

export const changeCurrency = currency => dispatch => 
  dispatch({
    type: CHANGE_CURRENCY,
    payload: currency
  })

export const UPDATE_CART_PRODUCT_QUANTITY = 'UPDATE_CART_PRODUCT_QUANTITY';

export const updateCartProductQuantity = product => dispatch =>
  dispatch({
    type: UPDATE_CART_PRODUCT_QUANTITY,
    payload: product
  })

export const INIT_LOCAL_STORE_CART = 'INIT_LOCAL_STORE_CART';

export const initLocalStoreCart = (products) => dispatch => 
  dispatch({
    type: INIT_LOCAL_STORE_CART,
    payload: products
  })

export const INIT_CART = 'INIT_CART';

export const initCart = () => ({
  type: INIT_CART
})
