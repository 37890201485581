
import {
    SET_BILLING, 
    SET_STEP,
    SET_TRANSPORT,

    FETCH_TRANSPORTS_PENDING,
    FETCH_TRANSPORTS_SUCCESS,
    FETCH_TRANSPORTS_ERROR,

    INIT_ORDER,
    FINISH_ORDER_PENDING,
    FINISH_ORDER_SUCCESS,
    FINISH_ORDER_ERROR
} from '../actions/order';

const initialState = {
    transports: [],
    transport: {},
    billing: {},
    step: 0,
    pending: true,
    orderFinished: false
};

/*const initialState = {
    transports: [],
    transport: {
        "transport_id": 1,
        "type": "ceskaPosta",
        "title": "Česká pošta, služba Balík do ruky",
        "CYK": 300,
        "sk": 10
    },
    billing: {
        organization: 'test x',
        street: 'test x',
        hn: '321',
        city: 'test x',
        zip: '654',
        name: 'test xxx',
        telephone: '789654123',
        email: 'test@test.te',
        note: '',
        state: 'cr',
        ico: '',
        dic: ''
    },
    step: 2,
    pending: true,
    orderFinished: false
};*/

export const order = (state = initialState, action) => {
    switch (action.type) {
        case SET_STEP: 
            return {
                ...state,
                step: action.payload
            }
        case SET_BILLING:
            return {
                ...state, 
                billing: action.payload
            }
        case SET_TRANSPORT: 
            return {
                ...state,
                transport: action.payload
            }
        case FETCH_TRANSPORTS_PENDING:
            return {
                ...state,
                pending: true
            }
        case FETCH_TRANSPORTS_SUCCESS: 
            return {
                ...state,
                transports: action.payload,
                pending: false
            }
        case FETCH_TRANSPORTS_ERROR:
            return {
                ...state,
                error: action.error,
                pending: false
            }
        case INIT_ORDER: {
            return {
                ...state,
                orderFinished: false
            }
        }
        case FINISH_ORDER_PENDING:
            return {
                ...state,
                orderFinished: false
            }
        case FINISH_ORDER_SUCCESS:
            return {
                ...state,
                transport: {},
                billing: {},
                step: 0,
                orderFinished: true
            }
            // return {
            //     ...state
            // };
        case FINISH_ORDER_ERROR: 
            return {
                ...state,
                error: action.error
            }
        default:
            return state
    }
}

export const getStep = state => state.order.step;

export const getTransports = state => state.order.transports;

export const getTransport = state => state.order.transport;

export const getOrderPending = state => state.order.pending;

export const getBilling = state => state.order.billing;

export const getOrderState = state => state.order.orderFinished;

export const getTransportCost = state => state.order.transport[state.cart.selectedCurrency === 'CZK' ? 'CZK': 'EUR'];
