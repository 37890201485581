import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Button, Container, Sidebar, Menu, Segment, Header } from 'semantic-ui-react'
import { connect } from 'react-redux';
import { setEditingMode } from '../../../actions/admin'
import { getEditingMode } from '../../../reducers/admin'
import { withRouter } from "react-router";

import './Admin.css'

function Admin(props) {
  const redirect = (url) => props.history.push(`/admin/${url}`);
  

  return (
    <Sidebar.Pushable as={Segment} style={{ overflow: 'hidden'}}>
      <Sidebar
          as={Menu}
          animation='push'
          icon='labeled'
          inverted
          vertical
          visible={true}
          width='thin'
      >
        <Menu.Item onClick={() => redirect('')}>Administrace</Menu.Item>
        <Menu.Item onClick={() => redirect('sprava-produktu')}>Správa produktů</Menu.Item>
        <Menu.Item onClick={() => redirect('sprava-textu')}>Správa textů</Menu.Item>
        <Menu.Item style={{color: 'gray'}}>Správa objednávek</Menu.Item>
      </Sidebar>

    <Sidebar.Pusher>
      <Segment basic>
        {props.children}
        {/*
        <Header as='h3'>Application Content</Header>
        Administrace<br />
    <Button onClick={() => props.setEditingMode(!props.editingMode)}>Editační mód</Button>
        */}
      </Segment>
    </Sidebar.Pusher>
  </Sidebar.Pushable>
  )
}

const mapStateToProps = state => ({
  editingMode: getEditingMode(state)
})

export default withRouter(
  connect(mapStateToProps, { 
    setEditingMode 
})(Admin));