import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import rootReducer from './reducers';
import registerServiceWorker from './registerServiceWorker';
import thunk from 'redux-thunk';
import { Router } from 'react-router-dom';

import ReactGA from 'react-ga';
import { createBrowserHistory } from 'history';

import 'semantic-ui-css/semantic.min.css'

import './index.css';


//Polyfill for .find()
import './ArrayFind';

const middleware = [thunk];
const initialState = {};
const store = createStore(rootReducer,
                          initialState,
                          compose(applyMiddleware(...middleware),
                        // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()                        
                        )
);

//ReactGA.initialize(`XXXXX-XXX`);

const history = createBrowserHistory();

/*history.listen(location => {
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
});
*/

ReactDOM.render(
    <Provider store={store}>
        <Router history={history}>
            <App />
        </Router>
    </Provider>,
    document.getElementById('root')
);
registerServiceWorker();
