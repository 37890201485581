import React from 'react'
import { Button, Message } from 'semantic-ui-react'
import '../Pages/Pages.css';

const CookiesBanner = ({ acceptCookies }) => (
  <div className='CookiesBanner'>
    <Message floating info size='big'>
      <Message.Header>Cookies</Message.Header>
      <p>Tyto webové stránky používají k poskytování svých služeb soubory Cookies.<br /> 
        Používáním těchto webových stránek souhlasíte s použitím souborů Cookies.&nbsp;<a href='/cookies'>Více informací o cookies.</a></p>
      <Button onClick={acceptCookies}>Souhlasím</Button>
    </Message>
  </div>
)

export default CookiesBanner;