import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { loadComponent, ProtectedRoute, PublicRoute } from './routing-utils';
import RoutesList from './Routes';

const publicRoutes = RoutesList.public.map(
  r => ({
    ...r,
    component: loadComponent(r.component)
  })
)

const protectedRoutes = RoutesList.protected.map( 
  r => ({
    ...r,
    component: loadComponent(r.component)
  })

)

function Routes() {
  return (
    <Switch>
    
      {publicRoutes.map(
        (r, i) => <PublicRoute exact path={r.path} component={r.component} title={r.title} key={i} />
      )}

      {protectedRoutes.map(
        (r, i) => <ProtectedRoute exact path={r.path} component={r.component} title={r.title} key={i} />
      )}

      {/* 404 Not Found */}
      <Redirect to="/domu" /> 
  
    </Switch>
  )
}
  
export default Routes;

  
