export const FETCH_PRODUCTS = 'FETCH_PRODUCTS';
export const FETCH_PRODUCT = 'FETCH_PRODUCT';
export const FETCH_REQUEST_PRODUCT = 'FETCH_REQUEST_PRODUCT';
export const FETCH_PRODUCTS_HOME = 'FETCH_PRODUCTS_HOME';
export const CHANGE_CURRENCY = 'CHANGE_CURRENCY';

export const ADD_TO_CART = 'ADD_TO_CART';
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export const FINISH_ORDER = 'FINISH_ORDER';

export const FETCH_ZOOM_IMAGE = 'FETCH_ZOOM_IMAGE';


export const FETCH_ARTICLES = 'FETCH_ARTICLES';
export const SET_ACTIVE_ARTICLE = 'SET_ACTIVE_ARTICLE';

export const SET_LOGIN = 'SET_LOGIN';
export const SET_LOGOUT = 'SET_LOGOUT';

