import axios from './config';

export const fetch = (url, { pending, success, error }) => dispatch => {
    dispatch(pending());
    
    axios.get(url).then(({ data, status }) => {
    

        (status === 401) && dispatch(error(true));
        

        dispatch(success(data))
        
    }).catch(err => dispatch(error()));
}

export const post = (url, { pending, success, error, cleanup }, body) => dispatch => {
    dispatch(pending());
    axios.post(url, body).then(({ data, status }) => {

        !!data ? dispatch(success(data)) : dispatch(success());

        !!cleanup && cleanup.forEach(fnc => {
            dispatch(fnc());
        });
        
    }).catch(err => {

        dispatch(error('error'))
    
    })
}

export const patch = (url, { pending, success, error, cleanup }, body) => dispatch => {
    dispatch(pending());
    axios.patch(url, body).then(({ data, status }) => {
        
        !!data ? dispatch(success(data)) : dispatch(success());

        !!cleanup && cleanup.forEach(fnc => {
            dispatch(fnc());
        });
        
    }).catch(err => {
        dispatch(error('error'))
    })
}