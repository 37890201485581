
import { fetch, post } from './middleware';
import axios from './config';
import {
    fetchArticlesPending,
    fetchArticlesSuccess,
    fetchArticlesError,

    fetchArticlesPreviewPending,
    fetchArticlesPreviewSuccess,
    fetchArticlesPreviewError,

    saveArticlePending,
    saveArticleSuccess,
    saveArticleError,

    deleteArticlePending,
    deleteArticleSuccess,
    deleteArticleError,

    reorderArticlesPending,
    reorderArticlesSuccess,
    reorderArticlesError,

    uploadImagePending,
    uploadImageSuccess,
    uploadImageError
} from '../actions/articles'

export const fetchArticles = () =>
    fetch(
        '/articles', 
        {
            pending: fetchArticlesPending,
            success: fetchArticlesSuccess,
            error: fetchArticlesError
        }
    );

export const fetchArticlesPreview = () => 
    fetch(
        '/articles/url', 
        {
            pending: fetchArticlesPreviewPending,
            success: fetchArticlesPreviewSuccess,
            error: fetchArticlesPreviewError
        }
    );

export const saveArticle = (article, action) =>
    post(
        'articles/save',
        {
            pending: saveArticlePending,
            success: saveArticleSuccess,
            error: saveArticleError
        },
        { article, action }
    )
        
export const deleteArticle = id => 
    fetch(
        `articles/delete/${id}`,
        {
            pending: deleteArticlePending,
            success: deleteArticleSuccess,
            error: deleteArticleError
        }
    )

export const reorderArticles = articles => 
    post(
        'articles/reorder',
        {
            pending: reorderArticlesPending,
            success: reorderArticlesSuccess,
            error: reorderArticlesError
        },
        {articles: articles}
)

export const uploadImage = image => axios.post('/images/upload', image);
