export const FETCH_TEXT_PENDING = 'FETCH_TEXT_PENDING';
export const FETCH_TEXT_SUCCESS = 'FETCH_TEXT_SUCCESS';
export const FETCH_TEXT_ERROR   = 'FETCH_TEXT_ERROR';


export const fetchTextPending= () => ({
  type: FETCH_TEXT_PENDING 
})
  
export const fetchTextSuccess = (text) => ({
  type: FETCH_TEXT_SUCCESS,
  payload: text
})

  
export const fetchTextError = (error) => ({
  type: FETCH_TEXT_ERROR,
  error: error
})


export const PATCH_TEXT_PENDING = 'PATCH_TEXT_PENDING';
export const PATCH_TEXT_SUCCESS = 'PATCH_TEXT_SUCCESS';
export const PATCH_TEXT_ERROR   = 'PATCH_TEXT_ERROR';

export const patchTextPending = () => ({
  type: PATCH_TEXT_PENDING 
})
  
export const patchTextSuccess = (text) => ({
  type: PATCH_TEXT_SUCCESS,
  payload: text
})

  
export const patchTextError = (error) => ({
  type: FETCH_TEXT_ERROR,
  error: error
})