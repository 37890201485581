import { post } from './middleware';
import {
    saveChangesPending,
    saveChangesSuccess,
    saveChangesError
} from '../actions/modal';

import { 
    closeModal
} from '../actions/modal'

import {
    updateProduct
} from '../actions/products'

import {
 fetchProduct
} from './products'

export const saveChanges = (data) =>
    post(
        '/auth/editable/text',
        {
            pending: saveChangesPending,
            success: () => saveChangesSuccess(data),
            error: saveChangesError,
            cleanup: [
                closeModal,
               // () => fetchProduct(data.values.href)
            ]
        },
        data
    )
