export default {
    public: [
        {
            path: '/',
            component: '/Home/Home',
            title: 'Domů'
        },
        {
            path: '/domu',
            component: '/Home/Home',
            title: 'Domů'
        },
        {
            path: '/kontakt',
            component: '/Contact/Contact',
            title: 'Kontakt'
        },
        {
            path: '/obchodni-podminky',
            component: '/TaC/TaC',
            title: 'Obchodní podmínky'
        },
        {
            path: '/produkty',
            component: '/Products/Products',
            title: 'Naše didaktické plakáty'
        },
        {
            path: '/produkt/:href/lupa',
            component: '/Zoom/Zoom',
            title: 'Lupa'
        },
        {
            path: '/produkt/:href',
            component: '/Product/Product',
            title: 'Produkt'
        },
        {
            path: '/kosik',
            component: '/Cart/Cart',
            title: 'Košík'
        },
        {
            path: '/objednavka',
            component: '/Order/Order',
            title: 'Objednávka'
        },
        {
            path: '/gdpr',
            component: '/GDPR/Gdpr',
            title: 'GDPR'
        },
        {
            path: '/cookies',
            component: '/Cookies/Cookies',
            title: 'Cookies'
        },
        {
            path: '/admin/login',
            component: '/Admin/Login',
            title: 'Přihlášení'
        }
    ],
    protected: [
        /*{
            path: '/admin/hotove-objednavky',
            component: '/Admin/OrdersManager',
            title: 'Hotové objednávky'
        },
        {
            path: '/admin/nehotove-objednavky',
            component: '/Admin/OrdersManager',
            title: 'Nehotové objednávky'
        },*/
        {
            path: '/admin',
            component: '/Admin/Admin',
            title: 'Administrátor'
        },
        {
            path: '/admin/sprava-produktu',
            component: '/Admin/ManageProducts/ManageProducts.Page.js',
            title: 'Správa produktů'
        },
        {
            path: '/admin/sprava-produktu/:produkt',
            component: '/Admin/ManageProducts/ManageProduct.Page.js',
            title: 'Správa produktu'
        },
        {
            path: '/admin/sprava-textu',
            component: '/Admin/ManageTexts/ManageTexts.page.js',
            title: 'Správa textů'
        }
    ]
}