import React, { useState } from 'react'
import img from '../../resources/images/banner/01.jpg';
import NavBar from '../NavBar/NavBar';
import { Container } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { getText, getTextState } from '../../reducers/texts'
import { getRoute, getRouteName, getRoutes } from '../../reducers/router';
import { goTo } from '../../actions/router';
import EditableText from '../Shared/EditableText'
import useMediaQuery from '@material-ui/core/useMediaQuery';

import '../App.css'


//TODO: MODULAR STYLES OR WHAT???

const styles = {
  
  imgCover: {
    background: `url(${img}) no-repeat`,
    width: '100%',
    height: '100%',
    backgroundPosition: 'top center'
  }
}


function Header(props) { 
  const { textPending, homeIntroText, route, routeName } = props;
  const mobile = useMediaQuery('(max-width:800px)');
  
  return (
    <>
      <div className="Header">
        <NavBar/>
        {route === '/domu' ? (
          <div className='overlay'>
            <div style={styles.imgCover}>
              <div className="banner-text" style={{textShadow: '1px 1px 1px black'}}>
                <Container>
                  {!textPending && (
                    <>
                      <EditableText>
                        <h3 style={{textTransform: 'uppercase'}}>
                          {homeIntroText.title}
                        </h3>
                      </EditableText>
                      
                      <EditableText>
                        <h4 style={{fontSize: '2.44em', marginTop: '-15px'}} className='test-cklass'>
                          {homeIntroText.sub_title}
                        </h4>
                      </EditableText>
                      
                      <EditableText>
                        <p>
                          {homeIntroText.text}
                        </p>
                      </EditableText>
                    </>               
                  )}
                </Container>
              </div>
            </div>
          </div>
        ) : (
          <div className='little-overlay'>
            <div style={styles.imgCover}>
              {!mobile && (
                <Container>
                  <h3 className={`current-route`}>
                    {routeName}
                  </h3>
              </Container>
              )}
            </div>
          </div>
        )}
      </div>

    </>
  )
}

const mapStateToProps = state => ({
  homeIntroText: getText(state, 'home-intro-text'),
  textPending: getTextState(state),
  route: getRoute(state),
  routes: getRoutes(state),
  routeName: getRouteName(state)
})

export default connect(mapStateToProps, { goTo })(Header);

