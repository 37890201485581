export const initCartLocalStore = () => JSON.parse(localStorage.getItem('products')) || [];

export const addToLocalStore = (products) => localStorage.setItem('products', JSON.stringify(products));

export const removeFromLocalStore = (products) => products ? addToLocalStore(products) : addToLocalStore([]);

export const clearLocalStore = () => localStorage.clear();


export const setLoggedUser = (token) => localStorage.setItem('token', token);

export const getLoggedUser = () => localStorage.getItem('token');

export const isAuth = () => localStorage.getItem('token') ? true : false;

export const clearLoggedUser = () => localStorage.removeItem('token');

export const getToken = () => localStorage.getItem('token');


// for cookies

export const acceptCookies = () => localStorage.setItem('cookies', true)

export const areCookiesAccepted = () => {
    const cookies = !!localStorage.getItem('cookies');
    return cookies;
}
