export const FETCH_PRODUCTS_PENDING = 'FETCH_PRODUCTS_PENDING';
export const FETCH_PRODUCTS_SUCCESS = 'FETCH_PRODUCTS_SUCCESS';
export const FETCH_PRODUCTS_ERROR   = 'FETCH_PRODUCTS_ERROR';

export const fetchProductsPending = () => ({
  type: FETCH_PRODUCTS_PENDING
})

export const fetchProductsSuccess = (products) => ({
  type: FETCH_PRODUCTS_SUCCESS,
  payload: products
})

export const fetchProductsError = (error) => ({
  type: FETCH_PRODUCTS_ERROR,
  error: error
})

export const FETCH_HOME_PRODUCTS_PENDING = 'FETCH_HOME_PRODUCTS_PENDING';
export const FETCH_HOME_PRODUCTS_SUCCESS = 'FETCH_HOME_PRODUCTS_SUCCESS';
export const FETCH_HOME_PRODUCTS_ERROR   = 'FETCH_HOME_PRODUCTS_ERROR';

export const fetchHomeProductsPending = () => ({
  type: FETCH_HOME_PRODUCTS_PENDING
})

export const fetchHomeProductsSuccess = (products) => ({
  type: FETCH_HOME_PRODUCTS_SUCCESS,
  payload: products
})

export const fetchHomeProductsError = (error) => ({
  type: FETCH_HOME_PRODUCTS_ERROR,
  error: error
})

export const FETCH_PRODUCT_PENDING = 'FETCH_PRODUCT_PENDING';
export const FETCH_PRODUCT_SUCCESS = 'FETCH_PRODUCT_SUCCESS';
export const FETCH_PRODUCT_ERROR   = 'FETCH_PRODUCT_ERROR';

export const fetchProductPending = () => ({
  type: FETCH_PRODUCT_PENDING 
})

export const fetchProductSuccess = (product) => ({
  type: FETCH_PRODUCT_SUCCESS,
  payload: product
})

export const fetchProductError = (error) => ({
  type: FETCH_PRODUCT_ERROR,
  error: error
})

export const UPDATE_PRODUCT = 'UPDATE_PRODUCT';

export const updateProduct = (product) => ({
  type: UPDATE_PRODUCT,
  payload: product
})

export const PATCH_TEXT_PRODUCT_PENDING = 'PATCH_TEXT_PRODUCT_PENDING';
export const PATCH_TEXT_PRODUCT_SUCCESS = 'PATCH_TEXT_PRODUCT_SUCCESS';
export const PATCH_TEXT_PRODUCT_ERROR   = 'PATCH_TEXT_PRODUCT_ERROR';

export const patchTextProductPending = () => ({
  type: PATCH_TEXT_PRODUCT_PENDING
})

export const patchTextProductSuccess = (product) => ({
  type: PATCH_TEXT_PRODUCT_SUCCESS,
  payload: product
})

export const patchTextProductError = (error) => ({
  type: PATCH_TEXT_PRODUCT_ERROR,
  error: error
})

export const POST_SIZE_PRODUCT_PENDING = 'POST_SIZE_PRODUCT_PENDING';
export const POST_SIZE_PRODUCT_SUCCESS = 'POST_SIZE_PRODUCT_SUCCESS';
export const POST_SIZE_PRODUCT_ERROR   = 'POST_SIZE_PRODUCT_ERROR';

export const postSizeProductPending = () => ({
  type: POST_SIZE_PRODUCT_PENDING
})

export const postSizeProductSuccess = (product) => ({
  type: POST_SIZE_PRODUCT_SUCCESS,
  payload: product
})

export const postSizeProductError = (error) => ({
  type: POST_SIZE_PRODUCT_ERROR,
  error: error
})

export const PATCH_SIZE_PRODUCT_PENDING = 'PATCH_SIZE_PRODUCT_PENDING';
export const PATCH_SIZE_PRODUCT_SUCCESS = 'PATCH_SIZE_PRODUCT_SUCCESS';
export const PATCH_SIZE_PRODUCT_ERROR   = 'PATCH_SIZE_PRODUCT_ERROR';

export const patchSizeProductPending = () => ({
  type: PATCH_SIZE_PRODUCT_PENDING
})

export const patchSizeProductSuccess = (sizes) => ({
  type: PATCH_SIZE_PRODUCT_SUCCESS,
  payload: sizes
})

export const patchSizeProductError = (error) => ({
  type: PATCH_SIZE_PRODUCT_ERROR,
  error: error
})

export const DELETE_SIZE_PRODUCT_PENDING = 'DELETE_SIZE_PRODUCT_PENDING';
export const DELETE_SIZE_PRODUCT_SUCCESS = 'DELETE_SIZE_PRODUCT_SUCCESS';
export const DELETE_SIZE_PRODUCT_ERROR   = 'DELETE_SIZE_PRODUCT_ERROR';

export const deleteSizeProductPending = () => ({
  type: DELETE_SIZE_PRODUCT_PENDING
})

export const deleteSizeProductSuccess = (sizes) => ({
  type: DELETE_SIZE_PRODUCT_SUCCESS,
  payload: sizes
})

export const deleteSizeProductError = (error) => ({
  type: DELETE_SIZE_PRODUCT_ERROR,
  error: error
})
