import React from 'react'
import { connect } from 'react-redux'
import { getEditingMode } from '../../reducers/admin'

import '../App.css'

function EditableText(props) {

    if(!props.editingMode) 
        return props.children
    
    return React.Children.map(props.children, 
        child => React.cloneElement(child, {  
            className: `highlighted-text ${!!child.props.className ? child.props.className : ''}`
        })
    )
            

}

const mapStateToProps = state => ({
    editingMode: getEditingMode(state)  
})
export default connect(mapStateToProps)(EditableText)