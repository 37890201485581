import { 
    SET_ACTIVE_ARTICLE
} from '../actions/types';
import {
    FETCH_ARTICLES_PENDING,
    FETCH_ARTICLES_SUCCESS,
    FETCH_ARTICLES_ERROR,

    FETCH_ARTICLES_PREVIEW_PENDING,
    FETCH_ARTICLES_PREVIEW_SUCCESS,
    FETCH_ARTICLES_PREVIEW_ERROR,
    DELETE_ARTICLE_SUCCESS,
    SAVE_ARTICLE_SUCCESS,
    REORDER_ARTICLES_SUCCESS
} from '../actions/articles';


const initialState = {
    articles: [],
    articlesPreview: [],
    activeArticle: null,
    pending: true,
    error: null
};

const articles = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ARTICLES_PENDING:
        case FETCH_ARTICLES_PREVIEW_PENDING:
            return {
                ...state, 
                pending: true
            }
        case FETCH_ARTICLES_SUCCESS:
            return {
                ...state, 
                pending: false,
                articles: action.payload
            }
        case FETCH_ARTICLES_ERROR: 
            return {
                ...state,
                pending: false,
                error: action.error
            }
        case FETCH_ARTICLES_PREVIEW_SUCCESS:
            return {
                ...state, 
                pending: false,
                articlesPreview: action.payload
            }
        case FETCH_ARTICLES_PREVIEW_ERROR: 
            return {
                ...state,
                pending: false,
                error: action.error
            }
        case SET_ACTIVE_ARTICLE:
            return {
                ...state, 
                activeArticle: action.payload
            }
        case SAVE_ARTICLE_SUCCESS: 
            if(!action.payload)
                return state;

            return {
                ...state,
                articles: action.payload
            }
        case DELETE_ARTICLE_SUCCESS: 
            return {
                ...state,
                articles: action.payload
            }
        case REORDER_ARTICLES_SUCCESS:
            return {
                ...state,
                articles: action.payload
            }
        default:
            return state
    }      
}
export default articles;

export const getArticles = state => state.articles.articles;
export const getArticlesPending = state => state.articles.pending;
export const getArticlesError = state => state.articles.error;

export const getArticlesPreview = state => state.articles.articlesPreview

//.articles.map(({ article_id, title, hash }) => ({ article_id, title, hash }))