import axios from 'axios';
import { getLoggedUser, isAuth } from '../localStore';

let headers = {};

if(isAuth())
  headers =  {
    'Authorization': `bearer ${getLoggedUser()}`
  }  
  

  // const instance = axios.create({
  //   baseURL: 'http://localhost:8080/api',
  //   headers: headers
  // })

const instance = axios.create({
  baseURL: 'https://didakticke-fototapety.cz/df-backend/public/api',
  headers: headers
})


export default instance;
