import React, { useState } from 'react'
import { Button, Modal, Form, TextArea } from 'semantic-ui-react'
import { connect } from 'react-redux';
import { closeModal } from '../../../actions/modal';
import { getModalState, getContent } from '../../../reducers/modal';
import { saveChanges } from '../../../api/Editer';

//import EditorJs from "react-editor-js";

//import { EDITOR_JS_TOOLS } from "./constants";

function ModalTextEditer(props) {

  const [textArea, setTextArea] = useState(null);
  //const [richText, setRichText] = useState(null);

  const { open, content } = props;
/*
  const data = {
      blocks: [
        {
          type: "header",
          data: {
            text: "Editor.js",
            level: 1
          }
        },
        {
          type: "paragraph",
          data: {
            text:
              "Hey. Meet the new Editor. On this page you can see it in action — try to edit this text."
          }
        },
        {
          type: "header",
          data: {
            text: "Key features",
            level: 3
          }
        },
        {
          type: "list",
          data: {
            style: "unordered",
            items: [
              "It is a block-styled editor",
              "It returns clean data output in JSON",
              "Designed to be extendable and pluggable with a simple API"
            ]
          }
        }
      ]
  }*/
/*
  useEffect(() => {
    setEditorState('<br><b>ssss</b>')
  }, [])
  */
/*
  useEffect(() => {
    setRichText(content.values.text);
  }, [content])
  */

  const saveChanges = () => {
    
    const data = {
      ...content,
      values: {
        ...content.values,
        text: textArea
      }
    };

    !!textArea && props.saveChanges(data);
    
  }

  return (
    <Modal open={open}> 
      <Modal.Header>Editace textu</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Form>
            <TextArea 
              defaultValue={content.values.text} 
              onChange={(e, { value }) => setTextArea(value)}
              rows={9}
            />
          </Form> 
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button color='black' onClick={() => props.closeModal()}>
          Zrušit
        </Button>
        <Button
          content="Uložit"
          labelPosition='right'
          icon='checkmark'
          onClick={() => saveChanges()}
          positive
        />
      </Modal.Actions>
    </Modal>
  )
}

const mapStateToProps = state => ({
    open: getModalState(state),
    content: getContent(state)
})

export default connect(mapStateToProps, { closeModal, saveChanges })(ModalTextEditer);