import { 
    FETCH_ZOOM_IMAGE
} from '../actions/types';

const initialState = {
    product: {}
};

const zoom = (state = initialState, action) => {
switch (action.type) {
   case FETCH_ZOOM_IMAGE:
       return {...state, product: action.payload}
   default:
       return state
    }
}

export default zoom;
