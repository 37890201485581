export const OPEN_MODAL = 'OPEN_MODAL';

export const openModal = (props, values) => dispatch => 
  dispatch({
    type: OPEN_MODAL,
    payload: {props, values}
  })

export const CLOSE_MODAL = 'CLOSE_MODAL';

export const closeModal = () => dispatch => 
  dispatch({
    type: CLOSE_MODAL
  })

export const SAVE_CHANGES_PENDING = 'SAVE_CHANGES_PENDING';
export const SAVE_CHANGES_SUCCESS = 'SAVE_CHANGES_SUCCESS';
export const SAVE_CHANGES_ERROR   = 'SAVE_CHANGES_ERROR';

export const saveChangesPending = () => ({
  type: SAVE_CHANGES_PENDING
})

export const saveChangesSuccess = (changes) => ({
  type: SAVE_CHANGES_SUCCESS,
  payload: changes
})

export const saveChangesError = (error) => ({
  type: SAVE_CHANGES_ERROR,
  error
})