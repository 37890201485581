import { 
    OPEN_MODAL,
    CLOSE_MODAL,
    SAVE_CHANGES_PENDING,
    SAVE_CHANGES_SUCCESS,
    SAVE_CHANGES_ERROR
} from '../actions/modal';


const initialState = {
    content: {
        props: {},
        values: {
            text: null 
        }
    },
    open: false,
    error: null
}
    
const Modal = (state = initialState, action) => {
    switch (action.type) {
        case OPEN_MODAL: 
            return {
                ...state,
                content: action.payload,
                open: true
            }
        case CLOSE_MODAL:
            return {
                ...state,
                open: false
            }
        case SAVE_CHANGES_PENDING: 
            return {
                ...state
            }
        case SAVE_CHANGES_SUCCESS: 
            return {
                ...state,
                content: {
                    ...state.content, 
                    values: {
                        ...state.content.values,
                        text: action.payload
                    }
                } 
            }
        case SAVE_CHANGES_ERROR: 
            return {
                ...state,
                error: action.error
            }
        default:
            return state
    }
}
export default Modal;

export const getModalState = state => state.modal.open;
export const getContent = state => state.modal.content;

