import {
    GO_TO
} from '../actions/router';

const initialState = {
    route: '',
    routes: {
        'domu': {id: 0, name: 'Domů', route: '/domu'},
        'produkty': {id: 1, name: 'Naše didaktické plakáty', route: '/produkty'},
        'kontakt' :{id: 3, name: 'Kontakt', route: '/kontakt'},
        'kosik': {id: 4, name: 'Košík', route: '/kosik'},
        'tipy': {id: 5, name: 'Tipy', route: '/domu'},
        'obchodni-podminky': {id:6, name: 'Obchodní podmínky', route: '/obchodni-podmiky'},
        'gdpr': {id:7, name: 'GDPR', route: '/gdpr'},
        'cookies': {id: 8, name: 'Cookies', routes: '/cookies'},
        'objednavka': {id:7, name: 'Objednávka', route: '/objednavka'}
    }
};

export const router = (state = initialState, action) => {
    switch (action.type) {
        case GO_TO:
           
            return {
                ...state, 
                route: action.payload === '/' ? '/domu' : action.payload
            }      
        default:
            return state
    }
}

export const getRoute = state => state.router.route;
export const getRoutes = state => state.router.routes;
export const getRouteName = state => { 
    
    if(!!!state.router.route || state.router.route === '/')
        return

    if(state.router.route.includes('/produkt'))
        return state.router.routes['produkty'].name
    
    return state.router.routes[state.router.route.substr(1).toLowerCase()].name  
};
