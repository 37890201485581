import React from 'react';
import { Container, Loader } from 'semantic-ui-react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import Loadable from 'react-loadable';

const Loading = () => {
    return (
        <Container>
            <Loader />
        </Container>
    );
}

export const loadComponent = component => Loadable({
    loader: () => import(`../Pages${component}`),
    loading: Loading
})

const setTitle = title => {
    if(title === undefined)
        return;
    
    if(title.includes("</div>"))
        title = title.split('</div>')[1];
    
    document.title = `${title} | Didaktické fototapety`;
}

function ProtectedComponent({auth, component: Component, ...rest}) {
    setTitle(rest.title);
    return (
        <Route exact {...rest} render={(props) => (
        auth === true
            ? <Component {...props} />
            : <Redirect to={{
                pathname: '/admin/login'
            }} />
        )} />
    )
}

const mapStateToProps = state => ({
    auth: state.auth.isAuth
})

const ProtectedRoute = connect(mapStateToProps)(ProtectedComponent);

function PublicRoute({component: Component, ...rest}) {
    setTitle(rest.title);

    return (
      <Route {...rest} render={(props) => <Component {...props} />} />
    )
}

export {
    PublicRoute,
    ProtectedRoute
}


