import { combineReducers } from 'redux';

import { products } from './products';
import { order } from './order';
import { texts } from './texts'
import { router } from './router';
import modal from './modal';
import cart from './cart';
import articles from './articles';
import zoom from './zoom';
import auth from './auth';
import admin from './admin'

export default combineReducers({
  router,
  texts,
  order,
  products,
  modal,
  cart: cart,
  articles: articles,
  zoom: zoom,
  auth: auth,
  admin: admin
})
