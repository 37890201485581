import { 
    AUTH_PENDING,
    AUTH_SUCCESS,
    AUTH_ERROR,
    SET_LOGOUT,

    FETCH_AUTH_USER_PENDING,
    FETCH_AUTH_USER_SUCCESS,
    FETCH_AUTH_USER_ERROR
} from '../actions/auth';
import { setLoggedUser, isAuth, clearLoggedUser, getToken } from '../localStore';

const initialState = {
    isAuth: isAuth(),
    pending: false,
    error: false,
    token: getToken() || null,
    user: null
};


const auth = (state = initialState, action) => {
    switch (action.type) {
        case AUTH_PENDING:
        case FETCH_AUTH_USER_PENDING:
            return {
                ...state,
                pending: true,
                error: false
            }
        case AUTH_SUCCESS:
            setLoggedUser(action.payload.token);
            return {
                ...state, 
                token: action.payload.token,
                user: action.payload.user,
                isAuth: true,
                pending: false
            };
        case AUTH_ERROR: 
        case FETCH_AUTH_USER_ERROR:
            return {
                ...state,
                pending: false,
                error: true,
                token: null,
                user: null,
                isAuth: false
            }
        case FETCH_AUTH_USER_SUCCESS: 
            return {
                ...state,
                pending: false,
                user: action.payload
            }
        case SET_LOGOUT:
            clearLoggedUser();
            return {...state, isAuth: false};
        default:
            return state
    }      
}
export default auth;

export const getAuthState = state => state.auth.isAuth;
export const getAuthUser = state => state.auth.user;