
import React, { Fragment, useState } from "react";
import { StyledIcon, SVG } from "./Icon.styled";
import { StyledMenu } from "./Menu.styled";
import { ReactComponent as CloseIcon } from "./images/close.svg";
import { ReactComponent as BackIcon } from "./images/back.svg";
import { useEffect } from "react";

export const FullscreenMenu = ({ position, colorIcon, articlesPreview }) => {
  const [open, setOpen] = useState(false);

  const [data, setData] = useState([
    {
      label: 'Domů',
      url: '/domu',
      sub_title: null,
      sub_menu: null
    },
    {
      label: 'Naše didaktické fototapety',
      url: '/produkty',
      sub_title: null,
      sub_menu: null
    },
    {
      label: 'Tipy',
      sub_title: null,
      sub_menu: [],
      url: null
    },
    {
      label: 'Kontakt',
      url: '/kontakt',
      sub_title: null,
      sub_menu: null
    },
    {
      label: 'Košík',
      url: '/kosik',
      sub_title: null,
      sub_menu: null
    }
  ])

  useEffect(() => {
    if (!!articlesPreview) {
      const subMenu = articlesPreview.map(({ title, hash }) => ({
        label: title,
        sub_title: null,
        url: `/domu#${hash}`,
        sub_menu: null
      }));

      const index = data.findIndex(m => m.label === 'Tipy');

      data[index]['sub_menu'] = subMenu;
      setData(data);
    }
  }, [articlesPreview])
  

  // console.log(data);

  return (
    <MenuBurger
      open={open}
      setOpen={setOpen}
      position={'right'}
      colorIcon={'white'}
    >
      <Menu open={open} data={data} setOpen={setOpen}></Menu>
    </MenuBurger>
  );
};

function MenuBurger({ open, setOpen, children, position, colorIcon }) {

  const Icon = (props) => {
    return (
      <SVG
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        {...props}
      >
        <g
          id="HP"
          stroke="none"
          strokeWidth="2"
          fill="none"
          fillRule="evenodd"
          strokeLinecap="square"
        >
          <g transform="translate(-271.000000, -19.000000)" stroke={colorIcon}>
            <g id="Group" transform="translate(271.000000, 19.000000)">
              <line x1="0.444444444" y1="1.5" x2="32" y2="1" id="Line-4"></line>
              <line
                x1="0.444444444"
                y1="11.5"
                x2="32"
                y2="11"
                id="Line-4"
              ></line>
              <line
                x1="0.444444444"
                y1="22.5"
                x2="32"
                y2="22"
                id="Line-4"
                strokeWidth="3"
              ></line>
            </g>
          </g>
        </g>
      </SVG>
    );
  };
  return (
    <Fragment>
      <StyledIcon position={position} colorIcon={colorIcon}>
        <span className="navbar-menu">
          <div className="menu-btn" open={open} onClick={() => setOpen(!open)}>
            <Icon
              fill="inherit"
              stroke={colorIcon}
              strokeWidth="2px"
              width="32px"
              height="23px"
              name="icon-home"
            />
          </div>
        </span>
      </StyledIcon>
      {children}
    </Fragment>
  );
}

function Menu({ open, setOpen, data }) {
  const [sub, setSub] = useState(false);
  
  const [selectedItemMenu, toggleMenu] = useState(-1);

  function openSubMenu(index) {
    toggleMenu(selectedItemMenu === index ? -1 : index);
    setSub(!sub);
  }

  function returnToBack() {
    setOpen(false);
    setSub(false);
    toggleMenu(-1);
  }
  return (
    <StyledMenu open={open}>
      <div className="remove-icon-mobile" onClick={returnToBack}>
        <CloseIcon />
      </div>
      <div className="menu_mobile">
        <ul id="lista" className={sub ? "move" : ""}>
          {data.map((item, index) => (
            <li
              className={item.sub_menu ? "item-level-1" : ""}
              onClick={() => {
                return item.sub_menu ? openSubMenu(index) : null;
              }}
              key={`item-${index}`}
            >
              <a href={item.url}>{item.label}</a>
              <SubMenu
                item={item}
                selectedItemMenu={selectedItemMenu}
                index={index}
                setSub={setSub}
                hasBeenClickedOn={() => {
                  setOpen(false);
                }}
              ></SubMenu>
            </li>
          ))}
        </ul>
      </div>
    </StyledMenu>
  );
}

function SubMenu({ item, selectedItemMenu, index, hasBeenClickedOn }) {
  //console.log(item)
  return (
    <Fragment>
      {item.sub_menu && (
        <ul className={`sub-menu ${selectedItemMenu === index ? "open" : ""}`}>
          <span className="return-level-1">
            <BackIcon />
          </span>
          <h3>{item.label}</h3>
          {item.sub_menu.map((sub_item, i) => (
              <li key={i}>
                <a href={sub_item.url} onClick={hasBeenClickedOn}>
                  <h4>{sub_item.label}</h4>
                  <p>{sub_item.sub_title}</p>
                </a>
              </li>
            ))}
        </ul>
      )}
    </Fragment>
  );
}
