import React, { useState, createRef } from 'react'
import { Menu, Popup, List, Icon, Placeholder, Label, Table } from 'semantic-ui-react'
import { Link } from 'react-router-dom';
import { useScrollTrigger } from '@material-ui/core';

import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';

function DesktopMenu({ route, menu, articlesPreview, totalSum, currency, moneyFormat, products, selectedCurrency }) {

    const [open, setOpen] = useState(false)

    const ref = createRef()

    return (
        <Menu secondary className='main-menu'>
            <Menu.Item 
                position='right'
                name={menu['domu'].route} 
                active={route === menu['domu'].route || route === ''}
                as={Link} 
                to={menu['domu'].route}
            >
                {menu['domu'].name}
            </Menu.Item>

            <Menu.Item 
                name={menu['produkty'].route} 
                active={route === menu['produkty'].route || route.includes('/produkt')}
                as={Link} 
                to={menu['produkty'].route}
            >
                {menu['produkty'].name}
            </Menu.Item>

            <Menu.Item 
                name={menu['kontakt'].route} 
                active={route === menu['kontakt'].route}
                as={Link} 
                to={menu['kontakt'].route}
            >
                {menu['kontakt'].name}
            </Menu.Item>

            <Popup
                className='popper-tips-menu'
                on='click'
                popper={{ id: 'popper-container', style: { zIndex: 2000 } }}
                position='bottom center'
                trigger={
                    <Menu.Item
                        ref={ref}
                        aria-owns={open ? 'menu-list-grow' : undefined}
                        aria-haspopup="true"
                        onClick={() => setOpen(!open)}
                    >
                        {menu['tipy'].name}
                    </Menu.Item>
                }
            >    
                {!!!articlesPreview ? (
                    <Placeholder style={{ minWidth: '200px' }}>
                        <Placeholder.Header>
                            <Placeholder.Line length='full' />
                            <Placeholder.Line length='short' />
                            <Placeholder.Line length='medium' />
                            <Placeholder.Line length='full' />
                            <Placeholder.Line length='long' />
                            <Placeholder.Line length='full' />
                        </Placeholder.Header>
                    </Placeholder>
                ) : (
                    <List selection verticalAlign='middle' size='big' divided>
                        {articlesPreview.map((article, i) =>
                            <List.Item key={i} as='a' href={`/domu#${article.hash}`}>
                                <Icon name={article.icon} />
                                <List.Content>
                                    <List.Header>{article.title}</List.Header>
                                </List.Content>
                            </List.Item>
                        )}
                    </List>
                )}
            </Popup>
            
            {totalSum <= 0 ? (
                <Menu.Item 
                    name={menu['kosik'].route} 
                    active={route === menu['kosik'].route}
                    as={Link} 
                    to={menu['kosik'].route}
                >
                    {menu['kosik'].name}
                </Menu.Item>
            ) : (

                <Popup 
                    position="bottom center"
                    hoverable
                    mouseLeaveDelay={500}
                    trigger={
                        <Menu.Item 
                            name={menu['kosik'].route} 
                            active={route === menu['kosik'].route}
                            as={Link} 
                            to={menu['kosik'].route}
                        >
                            {menu['kosik'].name}
                            {totalSum > 0 && 
                            <>
                            <Label color='teal' floating className='menu-price-label'>
                                {moneyFormat(totalSum)}
                                {' '}
                                {currency}
                            </Label>
                            </>
                        }
                        </Menu.Item>
                    } 
                    >
                    <Table basic='very' collapsing celled>
                        <Table.Body style={{fontSize: '1.1em'}}>
                            {products.map((product, i) => 
                                <Table.Row key={i}>
                                    <Table.Cell collapsing>
                                        {product.title}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {product.sizeVariant.size.size}
                                    </Table.Cell>
                                    <Table.Cell>
                                        <div className="no-wrap">
                                            <span className='red'>
                                                {moneyFormat(product.quantity * product.sizeVariant.size.price[selectedCurrency])}
                                                {' '}
                                                {currency}
                                            </span>
                                        </div>
                                    </Table.Cell>
                                </Table.Row>
                            )}
                        </Table.Body>
                    </Table>
                </Popup>
            )}
        </Menu>
    )
}

export default DesktopMenu;


        
            {/*
        
        {totalSum <= 0 ? (
          <Menu.Item
            name={menu.cart.route} 
            active={route.route === menu.cart.route}
            onClick={() => goTo(menu.cart.route)} 
            as={Link} 
            to='/kosik'
          >
            Košík
          </Menu.Item>
        ) : (                
            <Popup 
              position="bottom center"
              hoverable
              mouseLeaveDelay={500}
              trigger={
                <Menu.Item
                  name={menu.cart.route} 
                  active={current === menu.cart.route}
                  onClick={() => setCurrent(menu.cart.route)} 
                  as={Link} 
                  to='/kosik'
                >
                  Košík
                  {totalSum > 0 && 
                    <>
                      <Label color='teal' floating style={{borderRadius: '6px'}}>
                        {moneyFormat(totalSum)}
                        {' '}
                        {currency}
                      </Label>
                    </>
                  }
                </Menu.Item>
              } 
            >
              <Table basic='very' collapsing>
              <Table.Body style={{fontSize: '1.1em'}}>
                {products.map((product, i) => 
                  <Table.Row key={i}  >
                      <Table.Cell collapsing>
                        {product.title}
                      </Table.Cell>
                      <Table.Cell>
                        {product.size.size}
                      </Table.Cell>
                      <Table.Cell>
                        <div className="no-wrap">
                          {moneyFormat(product.quantity * product.size[selectedCurrency])}
                          {' '}
                          {currency}
                        </div>
                      </Table.Cell>
                  </Table.Row>
                )}
                </Table.Body>
              </Table>
            </Popup>
          )}
            
                </Menu>*/}


                /*

                                        <MenuList>
                            {articlesPreview.map((article, i) => 
                            <MenuItem 
                                key={i} 
                                style={classes.menuItem}
                                onClick={e => {handleScroll(article.hash, e)}}
                            >
                                {article.title}
                            </MenuItem> 
                            )}
                        </MenuList>
                        */