import {
    SET_EDITING_MODE
} from '../actions/admin';


const initialState = {
    editingMode: false
};

const admin = (state = initialState, action) => {
    switch (action.type) {
        case SET_EDITING_MODE:
            return {
                ...state, 
                editingMode: action.payload
            }
        default:
            return state
    }      
}
export default admin;

export const getEditingMode = state => state.admin.editingMode
