import React from 'react';
import Routes from '../Routes';
//TODO: REACT MODULAR CSS ??
import '../Pages/Pages.css'

function Main({ adminClass, style }) {
  return (
    <div className={`Main ${adminClass ? adminClass: ''}`} style={style}>
      <Routes />
    </div>
  )
}

export default Main;

