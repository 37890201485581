import React, { useEffect } from 'react';
import Layout from './Layout/Layout';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import { initLocalStoreCart } from '../actions/cart';
import { initCartLocalStore } from '../localStore';
import { goTo } from '../actions/router'

import './App.css';


function App(props) {

  useEffect(() => {   
    props.initLocalStoreCart(initCartLocalStore());

    //browser Back/Forward button fix
    window.addEventListener('popstate', () => 
      goTo(window.location.pathname)
    )

  }, [])

  useEffect(() => {
    props.goTo(window.location.pathname)
  }, [window.location.pathname])

  return <Layout />
}

export default withRouter(connect(null, { initLocalStoreCart, goTo })(App))