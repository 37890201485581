export const SET_BILLING = 'SET_BILLING'

export const setBilling = (billing) => ({
    type: SET_BILLING,
    payload: billing
})

export const SET_STEP = 'SET_STEP';

export const setStep = (step) => ({
    type: SET_STEP,
    payload: step
})

export const SET_TRANSPORT = 'SET_TRANSPORT';

export const setTransport = (transport) => ({
  type: SET_TRANSPORT,
  payload: transport
})


export const FETCH_TRANSPORTS_PENDING = 'GET_TRANSPORTS_PENDING';
export const FETCH_TRANSPORTS_SUCCESS = 'GET_TRANSPORTS_SUCCESS';
export const FETCH_TRANSPORTS_ERROR   = 'GET_TRANSPORTS_ERROR';

export const fetchTransportsPending = () => ({
  type: FETCH_TRANSPORTS_PENDING
})

export const fetchTransportsSuccess = (transports) => ({
  type: FETCH_TRANSPORTS_SUCCESS,
  payload: transports
})

export const fetchTransportsError = (error) => ({
  type: FETCH_TRANSPORTS_ERROR,
  error: error
})

export const INIT_ORDER = 'INIT_ORDER';

export const initOrder = () => ({
  type: INIT_ORDER
})

export const FINISH_ORDER_PENDING = 'FINISH_ORDER_PENDING';
export const FINISH_ORDER_SUCCESS = 'FINISH_ORDER_SUCCESS';
export const FINISH_ORDER_ERROR   = 'FINISH_ORDER_ERROR';

export const finishOrderPending = () => ({
  type: FINISH_ORDER_PENDING
})

export const finishOrderSuccess = () => ({
  type: FINISH_ORDER_SUCCESS
})

export const finishOrderError = (error) => ({
  type: FINISH_ORDER_ERROR,
  error: error
})
