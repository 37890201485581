import {
    FETCH_TEXT_PENDING,
    FETCH_TEXT_SUCCESS,
    FETCH_TEXT_ERROR,
    PATCH_TEXT_SUCCESS
} from '../actions/texts';

const initialState = {
    texts: [],
    pending: true
};

export const texts = (state = initialState, action) => {

    switch (action.type) {
        case FETCH_TEXT_PENDING:
            return {
                ...state, 
                pending: true
            }
        case FETCH_TEXT_SUCCESS:

            const text = {
                name: action.payload.name,
               ...JSON.parse(action.payload.text)
            }
            return {
                ...state, 
                pending: false,
                texts: [...state.texts, text]
            };
        case FETCH_TEXT_ERROR:
            return {
                ...state, 
                pending: false,
                error: action.error
            }
        case PATCH_TEXT_SUCCESS: {
            const text = {
                name: action.payload.name,
                ...JSON.parse(action.payload.text)
            }
            return {
                ...state, 
                pending: false,
                texts: [...state.texts, text]
            };
        }
        default:
            return state
    }
}

export const getText = (state, name) => state.texts.texts.find(t => t.name === name);
export const getTextState = (state) => state.texts.pending;