import axios from './config';
import {
    fetchAuthUserPending,
    fetchAuthUserSuccess,
    fetchAuthUserError
} from '../actions/auth';
import { fetch } from './middleware';


const auth = user => new Promise((resolve, reject) => {
    axios.post('/auth', user)
        .then(res => {
            axios.defaults.headers.common['Authorization'] = `bearer ${res.data.token}`;

            resolve(res);
        })
        .catch(err => {
            axios.defaults.headers.common['Authorization'] = null;
            reject(err);
        })
})
/*
export const getAuthUser = () => {
    getAuthUserPending();

    axios.get('/auth/me').then(res => {
        getAuthUserSuccess(res.data.user)
    }).catch(err => {
        getAuthUserError()
    })
}*/


export const fetchAuthUser = () =>
    fetch(
        '/auth/me', 
        {
            pending: fetchAuthUserPending,
            success: fetchAuthUserSuccess,
            error: fetchAuthUserError
        }
    );
/*
const getOrders = (status) => {
   return axios.get(`/auth/orders?status=${status}`);
}

const generateBill = (id) => {
   // window.open(`http://didakticke-fototapety.cz/server/api/v1/auth/${id}/generate-pdf?token=${token}`, "_blank");
}


const updateAddress = (address) => {

   return axios.post('/auth/order/update', address);
}


const orderCompleted = (orderId) => {
    return axios.post('/auth/order/completed', orderId);
}
*/
export default { auth /*, getOrders , generateBill, updateAddress, orderCompleted*/ };
