import { 
    ADD_TO_CART,
    REMOVE_FROM_CART,
    CHANGE_CURRENCY,
    UPDATE_CART_PRODUCT_QUANTITY,
    INIT_LOCAL_STORE_CART,
    INIT_CART
} from '../actions/cart';


import { addToLocalStore, removeFromLocalStore } from '../localStore';

const initialState = {
    products: [],
    selectedCurrency: 'CZK'
}
    
const cart = (state = initialState, action) => {
    switch (action.type) {
        case ADD_TO_CART:
            addToLocalStore([...state.products, action.payload]);
            return {
                ...state,
                products: [...state.products, action.payload]
            }
        case REMOVE_FROM_CART: {  
            const products = state.products.filter(p => p.sizeVariant.size_id !== action.payload.sizeVariant.size_id);
            removeFromLocalStore(products);        
            return {
                ...state,
                products
            }
        }
        case UPDATE_CART_PRODUCT_QUANTITY: {
            const products = state.products.map(p => {
                p.sizeVariant.size_id === action.payload.sizeVariant.size_id && (p.quantity = action.payload.quantity);

                return p;
            })

            addToLocalStore(products);
            
            return {
                ...state,
                products
            }
        }
        case INIT_LOCAL_STORE_CART: 
            return {
                ...state,
                products: action.payload
            }
        case INIT_CART:
            removeFromLocalStore();
            
            return {
                ...state, 
                products: []
            }
        case CHANGE_CURRENCY: 
            return {
                ...state,
                selectedCurrency: action.payload
            }
        default:
            return state
    }
}


/*
const isDuplicate = (product, products) => {
    const p = products.filter(p => p.size.size_id == product.size.size_id);
    return p;
}

const isQuantityEq = (quantity, p) => {
    if(quantity != p.quantity)
        return true;

    return false;
}

const removeProduct = (product, products) => {
    return products.filter( _product => {
        if(_product.product_id == product.product_id)
            if(_product.size.size_id == product.size.size_id)
                return;
            return _product;
    })
}

const calcTotalPrice = (product, totalPrice, type, currency) => {
    const price = product.size[currency];
    const quantity = product.quantity;
    if(type == '+')
        totalPrice += parseInt(price) * quantity;
    else
        totalPrice -= parseInt(price) * quantity;
    return totalPrice;
}
*/
export default cart;
    

export const getSelectedCurrency = (state) => state.cart.selectedCurrency;
export const getCartProducts = (state) => state.cart.products;

export const getProductsSum = (state) => {
    const { products, selectedCurrency } = state.cart;

    return products.reduce((sum, val) => 
        sum + (Number(val.sizeVariant.size.price[selectedCurrency]) * val.quantity) +
        (val.sizeVariant.deliveredWithFrame ? (val.sizeVariant.frame[selectedCurrency] * val.quantity) : 0)
    , 0)
}

export const getReadableCurrency = (state) => getSelectedCurrency(state) === 'CZK' ? 'Kč' : '€';